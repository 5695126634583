import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import Footer from './Footer';
import Navbar from './Navbar';

const useStyles = makeStyles(theme => ({
  '@global': {
    fontFamily: 'Georgia, system-ui, serif',
    p: {
      fontSize: 21,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  mainWrap: {
    width: '100%',
    flex: 1,
    backgroundColor: '#fff',
    marginBottom: 10,
    marginTop: theme.mixins.toolbar,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.mixins.toolbar,
    },
  },
  toolbar: theme.mixins.toolbar,
}));

const Layout = ({ children, hideNavbar, hideTitle }) => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              company
            }
          }
        }
      `}
      render={data => (
        <div className={classes.root}>
          {!hideNavbar && (
            <>
              <Navbar hideTitle={hideTitle} />
              <div className={classes.toolbar} />
            </>
          )}
          <div className={classes.mainWrap}>{children}</div>
          <Footer siteTitle={data.site.siteMetadata.company} />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
