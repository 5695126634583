import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';

import Footer from './Footer';
import Navbar from './Navbar';
import { COLORS } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    fontFamily: 'Georgia, system-ui, serif',
    a: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    p: {
      fontSize: 21,
      lineHeight: 1.75,
    },
    figcaption: {
      textAlign: 'center',
    },
  },
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: COLORS.lightWhite,
  },
  main: {
    backgroundColor: '#fff',
    marginBottom: 20,
    padding: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

const BlogLayout = ({ children, isBlog }) => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query SiteDocsQuery {
          site {
            siteMetadata {
              title
              company
            }
          }
        }
      `}
      render={data => (
        <div className={classes.root}>
          <Navbar isBlog={isBlog} />
          <div className={classes.toolbar} />
          <div className={classes.mainWrap}>
            <Container component="main" className={classes.main}>
              {children}
            </Container>
          </div>
          <Footer siteTitle={data.site.siteMetadata.company} />
        </div>
      )}
    />
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;
