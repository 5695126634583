import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { COLORS, spacing } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 10,
    borderTop: `1px solid ${COLORS.gray}`,
    padding: `${spacing(4)}px ${spacing(2)}px`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  links: {
    marginTop: 16,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    marginRight: 10,
    color: 'white',
    textDecoration: 'none',
  },
}));

const Footer = ({ siteTitle }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="h6" align="center" gutterBottom>
          <strong>PlainIce</strong>
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          Cool refreshing software
        </Typography>
        <Typography variant="body2" align="center">
          {'Copyright © '}
          <Link className={classes.link} to="/">
            PlainIce
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
      <div className={classes.links}>
        <div className={classes.grow} />
        <Link className={classes.link} to="/privacy.html">
          {' '}
          Privacy Policy{' '}
        </Link>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
