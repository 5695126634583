export const COLORS = {
  lightWhite: '#FBFDFF',
  white: '#FFF',
  lightBlack: '#222',
  lightGray: '#F3F5F6',
  mediumGray: '#B0B8BA',
  gray: '#A0A4A6',
  thisGray: 'rgba(0, 0, 0, 0.54)',
  babyBlue: '#50CAF9',
  blue: '#5368f1',
  plainice: '#2196f3',
};

export const BREAKPOINTS = {
  sm: '@media(min-width:600px)',
  md: '@media(min-width:960px)',
  topbar: 64,
  topbarSm: 56,
};

export function spacing(amount) {
  return amount * 8;
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.babyBlue}, ${COLORS.blue})`;

export const BORDER_RADIUS = '10px';
