import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default props => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "ice-576352_1280.png" }) {
          childImageSharp {
            fixed(width: 35, height: 35) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 8,
          maxWidth: '100%',
        }}
      >
        <Img fixed={data.placeholderImage.childImageSharp.fixed} />
      </div>
    )}
  />
);
