import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/styles';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Link } from 'gatsby';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { BREAKPOINTS, COLORS } from '../styles/constants';

import Cube from './cube';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 1002,
    boxShadow: 'none',
  },
  toolbar: {
    backgroundColor: COLORS.white,
    boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'row',
  },
  moreButton: {
    color: COLORS.lightBlack,
  },
  title: {
    color: COLORS.lightBlack,
    backgroundColor: COLORS.lightWhite,
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    boxShadow: 'none',
    color: 'white',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'rgb(0,0,0,0.87)',
    textDecoration: 'none',
  },
  navIconHide: {
    [BREAKPOINTS.md]: {
      display: 'none',
    },
    marginLeft: -12,
  },
}));

const Navbar = ({ isBlog }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component={Link}
          to={isBlog ? '/blog' : '/'}
          className={classes.title}
          variant="h6"
          color="inherit"
          noWrap
        >
          <Cube className={classes.icecube} />P
          <span className={classes.smallerCompany}>lain</span>I
          <span className={classes.smallerCompany}>ce</span>
        </Typography>
        <div style={{ flex: 1 }} />
        {!isBlog && (
          <div>
            <Button
              component={Link}
              to="/blog"
              variant="outlined"
              color="primary"
            >
              Blog
            </Button>
          </div>
        )}
        {isBlog && (
          <>
            <Hidden xsDown implementation="css">
              <div>
                <Button
                  component={Link}
                  to="/"
                  variant="outlined"
                  color="primary"
                >
                  Products
                </Button>
              </div>
            </Hidden>
            <Hidden smUp>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <div>
                    <IconButton
                      className={classes.moreButton}
                      aria-label="More"
                      aria-haspopup="true"
                      {...bindTrigger(popupState)}
                    >
                      <Icon>more_vert_icon</Icon>
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={popupState.close}
                        component={Link}
                        to="/"
                      >
                        Products
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </PopupState>
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
